import { sendRequest } from './index';
import { API_CORE_URL } from '@core/constants/API';

export const initService = () => {
	return sendRequest({
		url: `${API_CORE_URL}init`,
		method: 'GET'
	});
};

export const SearchService = (payload) => {
	if (payload) {
		const keyword = payload
			.split('&')
			.find((item) => item.split('=').shift() === 'keyword')
			.split('=')
			.pop();
		return sendRequest({
			url: `${API_CORE_URL}explore?search_keyword=${keyword}&${payload}`,
			method: 'GET'
		});
	}
};

export const SearchSuggestService = (payload) => {
	const { keyword, ...params } = payload;
	let serachConfig = '';
	if (Object.keys(params).length > 0) {
		serachConfig +=
			Object.keys(params)
				.map((key) => `${key}=${params[key]}`)
				.join('&');
	}
	return sendRequest({
		url: `${API_CORE_URL}explore?search_keyword=${keyword}&${serachConfig}`,
		method: 'GET'
	});
};

export const CategoriesService = (filters) => {
	return sendRequest({
		url: `${API_CORE_URL}categories${filters ? `?filters=${filters}` : ''}`,
		method: 'GET'
	});
};

export const CategoryByIdService = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}categories/${id}`,
		method: 'GET'
	});
};

export const CategoryContentsService = (id, params) => {
	if (id) {
		return sendRequest({
			url: `${API_CORE_URL}categories/${id}/contents?${params}`,
			method: 'GET'
		});
	}
};

export const CertificatesListService = (queryParams) => {
	return sendRequest({
		url: `${API_CORE_URL}certificates${queryParams}`,
		method: 'GET'
	});
};
export const CourseService = (payload, userIp) => {
	return sendRequest({
		url: `${API_CORE_URL}courses/${payload}`,
		method: 'GET',
		userIp: userIp
	});
};
export const LectureService = (payload) => {
	return sendRequest({
		url: `${API_CORE_URL}lectures/${payload}`,
		method: 'GET',
		// userIp: userIp
	});
};

export const CourseFeedbackSummary = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}courses/${id}/feedback`,
		method: 'GET'
	});
};
export const LectureFeedbackSummary = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}lectures/${id}/feedback`,
		method: 'GET'
	});
};

export const CourseComments = ({ id, page_size, page }) => {
	return sendRequest({
		url: `${API_CORE_URL}courses/${id}/comments?page_size=${page_size}&page=${page}`,
		method: 'GET'
	});
};
export const LectureComments = ({ id, page_size, page }) => {
	return sendRequest({
		url: `${API_CORE_URL}lectures/${id}/comments?page_size=${page_size}&page=${page}`,
		method: 'GET'
	});
};

export const CourseCurriculumService = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}courses/${id}/curriculums`,
		method: 'GET'
	});
};

export const CourseModule = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}courses/${id}/modules`,
		method: 'GET'
	});
};
export const LectureModule = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}lectures/${id}/modules`,
		method: 'GET'
	});
};
export const LectureRelaunchModule = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}lectures/${id}/relaunch`,
		method: 'GET'
	});
};

export const CourseCertificateService = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}courses/${id}/certificates`,
		method: 'GET'
	});
};

export const ContentCertificatePdfService = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}certificates/${id}/links`,
		method: 'GET'
	});
};

export const giftCodeService = (payload) => {
	return sendRequest({
		url: `${API_CORE_URL}gift-code`,
		method: 'POST',
		body: payload
	});
};
export const LectureCertificateService = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}lectures/${id}/certificates`,
		method: 'GET'
	});
};

export const AddToWishlistCourseService = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}courses/${id}/wishlist`,
		method: 'POST'
	});
};
export const AddToWishlistLectureService = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}lectures/${id}/wishlist`,
		method: 'POST'
	});
};

export const RemoveFromWishlistCourseService = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}courses/${id}/wishlist`,
		method: 'DELETE'
	});
};
export const RemoveFromWishlistLectureService = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}lectures/${id}/wishlist`,
		method: 'DELETE'
	});
};

export const courseSendFeedbackService = (payload) => {
	return sendRequest({
		url: `${API_CORE_URL}courses/${payload.id}/feedback`,
		method: 'POST',
		body: payload
	});
};
export const lectureSendFeedbackService = (payload) => {
	return sendRequest({
		url: `${API_CORE_URL}lectures/${payload.id}/feedback`,
		method: 'POST',
		body: payload
	});
};

export const ReportFeedbackService = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}feedback/${id}/reports`,
		method: 'POST'
	});
};

export const GetUserWishlist = (params) => {
	return sendRequest({
		url: `${API_CORE_URL}wishlist?${params}`,
		method: 'GET'
	});
};

export const GetUserWishlistMetaData = () => {
	return sendRequest({
		url: `${API_CORE_URL}wishlist/metadata`,
		method: 'GET'
	});
};

export const GetHomeContents = () => {
	return sendRequest({
		url: `${API_CORE_URL}home`,
		method: 'GET',
		// userIp: userIp
	});
};

export const GetMyUniContents = () => {
	return sendRequest({
		url: `${API_CORE_URL}my-uni`,
		method: 'GET'
	});
};

export const HomeShowMoreService = (id, params) => {
	return sendRequest({
		url: `${API_CORE_URL}home/${id}?${params}`,
		method: 'GET'
	});
};

export const MyUniShowMoreService = (id, params) => {
	return sendRequest({
		url: `${API_CORE_URL}my-uni/${id}?${params}`,
		method: 'GET'
	});
};

export const LectureChangeLogService = ({ id, page_size, page }) => {
	return sendRequest({
		url: `${API_CORE_URL}lectures/${id}/changelogs?page_size=${page_size}&page=${page}`,
		method: 'GET'
	});
};

export const LectureUpdateVersionService = (id) => {
	return sendRequest({
		url: `${API_CORE_URL}lectures/${id}/update`,
		method: 'POST'
	});
};

export const CertificateValidation = {
	POST: (payload) => {
		return sendRequest({
			url: `${API_CORE_URL}certificates/validation`,
			method: 'POST',
			body: payload
		});
	}
};
export const CourseRecommendation = {
	GET: (id) =>
		sendRequest({
			url: `${API_CORE_URL}lectures/${id}/recomendation`,
			method: 'GET'
		})
};

export const JobQualificationRecommendation = {
	GET: (id) =>
		sendRequest({
			url: `${API_CORE_URL}courses/${id}/recomendation`,
			method: 'GET'
		})
};

export const JobQualificationWaitingList = {
	POST: (id) =>
		sendRequest({
			url: `${API_CORE_URL}courses/${id}/waiting-list`,
			method: 'POST'
		})
};

export const CourseWaitingList = {
	POST: (id) =>
		sendRequest({
			url: `${API_CORE_URL}lectures/${id}/waiting-list`,
			method: 'POST'
		})
};

export const GetUserDashboard = () => {
	return sendRequest({
		url: `${API_CORE_URL}dashboard`,
		method: 'GET'
	});
};
