import { lazy } from 'react';

// ** TITLE TYPE CONSTANTS
import {
	HOME_TITLE,
	MAIN_PAGE_TITLE,
	HIDDEN_TITLE,
	SUB_PAGE_TITLE,
	BLANK_TITLE
} from '@core/constants/Title';
// ** Layout TYPE CONSTANTS
import { DEFAULT_LAYOUT, BLANK_MOBILE_LAYOUT } from '@core/constants/Layout';

const PagesRoutes = [
	{
		path: '/',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Home', type: HOME_TITLE },
			authentication: true
		}
	},
	{
		path: '/newsletter-confirm',
		exact: true,
		layout: BLANK_MOBILE_LAYOUT,
		meta: {
			title: { text: 'Verify Email', type: BLANK_TITLE }
		}
	},
	{
		path: '/job-qualifications/[...slug]',
		exact: false,
		layout: DEFAULT_LAYOUT,
		deprecated: true,
		meta: {
			title: {
				text: 'Job Qualifications Detail',
				type: HIDDEN_TITLE,
				display: false,
				localTitle: true
			}
		}
	},
	{
		path: '/category/[id]',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Categories', type: HIDDEN_TITLE, display: false }
		}
	},
	{
		path: '/courses/[...slug]',
		exact: false,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Course Detail', type: HIDDEN_TITLE, display: false, localTitle: true }
		}
		// add title to link
	},
	{
		path: '/myuni',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'MyUni', type: MAIN_PAGE_TITLE }
		}
	},
	{
		path: '/search',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Search', type: HIDDEN_TITLE }
		}
	},
	{
		path: '/wishlist',
		exact: true,
		layout: DEFAULT_LAYOUT,
		deprecated: true,
		meta: {
			title: { text: 'Wishlist', type: MAIN_PAGE_TITLE }
		}
	},
	{
		path: '/cart',
		exact: true,
		deprecated: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Cart', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/payment-result/[cart_id]',
		exact: true,
		deprecated: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Payment Result', type: HIDDEN_TITLE }
		}
	},
	{
		path: '/payment-result-subscription',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Payment Result', type: MAIN_PAGE_TITLE }
		}
	},
	{
		path: '/show-more-products/[origin]/[show_more_id]',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Show More', type: HIDDEN_TITLE }
		}
	},
	{
		path: '/404',
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'NotFound', type: BLANK_TITLE }
		}
	},
	{
		path: '/job-board',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Job board', type: MAIN_PAGE_TITLE }
			// protected: true
		}
	},
	{
		path: '/companies/[id]',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Company', type: SUB_PAGE_TITLE }
		}
	},
	{
		path: '/explore',
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Explore', type: HOME_TITLE }
		}
	},
	{
		path: '/certificates/validation',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Certificate Validation', type: SUB_PAGE_TITLE }
		}
	},
	{
		path: '/user-dashboard',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'User Dashboard', type: MAIN_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/my-applications',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'My Applications', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/my-applications/details/[id]',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Application Details', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/plans',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Plans', type: HOME_TITLE },
		}
	},
	{
		path: '/setting',
		exact: true,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Settings', type: SUB_PAGE_TITLE },
			protected: true
		}
	},
	{
		path: '/public-profile/[...slug]',
		exact: false,
		layout: DEFAULT_LAYOUT,
		meta: {
			title: { text: 'Public Profile', type: SUB_PAGE_TITLE }
		}
	},
	
];

export default PagesRoutes;
